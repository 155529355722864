import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './Rules.scss';
import { getRuleList, updateRules, removeRule } from '../../../../../services/ruleService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setRuleModalStatus, setRuleResMsg } from '../../../../../actions/ruleActions';
import { StatusSelector, CategoryRuleSelector, Loader } from '../../../../Common';

const statusArr = ['probably', 'maybe', 'no', '-'];

class Rules extends Component {
  state = {
    rules: undefined,
    expenseCategories: []
  };

  async componentDidMount() {
    try {
      const { memberInfo } = this.props.user;
      const expenseCategories = this.props.expense.categoryList;
      expenseCategories[0].display_name = 'default';
      this.setState({ expenseCategories });

      if (memberInfo && memberInfo.phone) {
        this.props.setRuleResMsg({ success: '', error: '' });
        const rules = await this.props.getRuleList(memberInfo.phone);
        if (rules) {
          rules.forEach((rule) => {
            if (!rule.prediction) rule.prediction = '-';
            if (!rule.amount_max) rule.amount_max = '';
            if (!rule.amount_min) rule.amount_min = '';
            if (!rule.category_update) rule.category_update = 'default';
            else rule.category_update = this.mapToCategoryDisplay(rule.category_update);
          });
        }
        this.setState({ rules });
      }
    } catch (e) {
      console.log(e);
    }
  }

  onAddNew = () => {
    let { rules } = this.state;
    rules.push({ name: '', prediction: '-', category_update: 'default' });
    this.setState({ rules });
  };

  onRemoveRule = async (rule) => {
    if (window.confirm('Are you sure you want to delete this rule?')) {
      const { rules } = this.state;
      const newRules = _.reject(rules, rule);
      this.setState({ rules: newRules });
      const { memberInfo } = this.props.user;
      await this.props.removeRule(rule, memberInfo.id);
    }
  };

  /**
   * @dec On name change event
   * @param {*} val
   * @param {*} ruleInd
   */
  _onNameChanged(val, ruleInd) {
    let { rules } = this.state;
    rules[ruleInd].name = val;
    rules[ruleInd].changed = true;

    this.setState({ rules });
  }

  /**
   * @dec On Status change event
   * @param {*} val
   * @param {*} ruleInd
   */
  _onStatusChange(val, ruleInd) {
    let { rules } = this.state;
    rules[ruleInd].prediction = val;
    rules[ruleInd].changed = true;

    this.setState({ rules });
  }

  /**
   * @dec On Status change event
   * @param {*} val
   * @param {*} ruleInd
   */
  _onCategoryChange(val, ruleInd) {
    let { rules } = this.state;
    rules[ruleInd].category_update = val;
    rules[ruleInd].changed = true;

    this.setState({ rules });
  }

  /**
   * @dec On max amount change event
   * @param {*} val
   * @param {*} ruleInd
   */
  _onMaxAmountChanged(val, ruleInd) {
    let { rules } = this.state;
    rules[ruleInd].amount_max = val;
    rules[ruleInd].changed = true;

    this.setState({ rules });
  }

  /**
   * @dec On min amount change event
   * @param {*} val
   * @param {*} ruleInd
   */
  _onMinAmountChanged(val, ruleInd) {
    let { rules } = this.state;
    rules[ruleInd].amount_min = val;
    rules[ruleInd].changed = true;

    this.setState({ rules });
  }

  /**
   * @description On save rules
   * @param {Object} e
   */
  onSubmit = async (e) => {
    e.preventDefault();
    let { rules } = this.state;
    if (rules && rules.length > 0) {
      const clonedRules = JSON.parse(JSON.stringify(rules));
      for (let ruleInd = 0; ruleInd < clonedRules.length; ruleInd++) {
        const rule = clonedRules[ruleInd];
        rule.prediction = rule.prediction === '-' ? null : rule.prediction;
        rule.amount_min = rule.amount_min ? Number(rule.amount_min) : null;
        rule.amount_max = rule.amount_max ? Number(rule.amount_max) : null;
        rule.category_update = this.mapToCategoryId(rule.category_update);
      }
      const { memberInfo } = this.props.user;
      await this.props.updateRules(memberInfo.phone, clonedRules);
    }
  };

  mapToCategoryId = (displayName) => {
    const { expenseCategories } = this.state;
    const category = _.find(expenseCategories, ['display_name', displayName]);
    return category ? category.id : null;
  };

  mapToCategoryDisplay = (id) => {
    const { expenseCategories } = this.state;
    const category = _.find(expenseCategories, ['id', id]);
    return category ? category.display_name : 'default';
  };

  _timeout;
  setErrorMsg = (msg) => {
    if (this._timeout) clearTimeout(this._timeout);
    this.props.setRuleResMsg({ success: '', error: msg });

    this._timeout = setTimeout(() => {
      this.props.setRuleResMsg({ success: '', error: '' });
    }, 3000);
  };

  render() {
    const { loader, successMsg, errorMsg } = this.props.rule;
    const { rules } = this.state;
    const { memberInfo } = this.props.user;

    return (
      <>
        <div className='modal-title'>
          <h5>{memberInfo.firstname}&apos;s Rules</h5>
        </div>
        <div className='add-expense'>
          {loader && !rules && <Loader />}
          {successMsg && <div className='alert alert-success'>{successMsg}</div>}
          {errorMsg && <div className='alert alert-danger'>{errorMsg}</div>}
          {rules && (
            <div className='expense-content dflex'>
              <button className='btn grey' type='button' onClick={this.onAddNew}>
                <FontAwesomeIcon icon={['fa', 'plus']} />
              </button>

              <form onSubmit={this.onSubmit} className='dflexfull'>
                <div className='description-rule-section'>Expense name: </div>
                <div className='category-rule-section'>Rule: </div>
                <div className='category-rule-section'>Category: </div>
                {rules.map((rule, ruleInx) => {
                  const ruleNameEditable = rule.rule_level !== 'display_name_id';
                  return (
                    <div key={`rule_i_${ruleInx}`}>
                      <div className='description-rule-section'>
                        {ruleNameEditable ? (
                          <input
                            type='text'
                            name='description'
                            value={rule.name}
                            placeholder='Description...'
                            onChange={(e) => this._onNameChanged(e.target.value, ruleInx)}
                            className='expense-input'
                            required
                          />
                        ) : (
                          <input type='text' name='description' value={rule.name} className='expense-input' disabled />
                        )}
                      </div>
                      <div className='category-rule-section fixcolorno'>
                        <div className='item expStatus'>
                          <StatusSelector
                            items={statusArr}
                            value={rule.prediction}
                            onSelect={(item) => this._onStatusChange(item, ruleInx)}
                            required
                          />
                        </div>
                      </div>
                      <div className='category-rule-section'>
                        <CategoryRuleSelector
                          items={this.state.expenseCategories}
                          value={rule.category_update}
                          onSelect={(item) => this._onCategoryChange(item, ruleInx)}
                        />
                      </div>
                      <div className='remove-rule-btn'>
                        <button className='btn grey' type='button' onClick={() => this.onRemoveRule(rule)}>
                          <FontAwesomeIcon icon={['fa', 'times']} />
                        </button>
                      </div>
                    </div>
                  );
                })}
                <div className='expense-submit'>
                  <button className='btn blue' type='submit' disabled={loader}>
                    Save
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>
      </>
    );
  }
}

Rules.propTypes = {
  setRuleModalStatus: PropTypes.func.isRequired,
  updateRules: PropTypes.func.isRequired,
  removeRule: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  rule: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  user: state.user,
  rule: state.rule,
  expense: state.expense
});

const ConnectedRules = connect(mapStateToProps, {
  getRuleList,
  setRuleModalStatus,
  setRuleResMsg,
  updateRules,
  removeRule
})(Rules);

export default ConnectedRules;
